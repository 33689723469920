import { createHashRouter, Outlet } from "react-router-dom";
import PublicRoutesValidation from "./public-routes/UserValidation";
import PublicRoutes from "./public-routes";
import ProtectedRoutes from "./protected-routes";

const RouterModule = createHashRouter([
  {
    path: "/",
    element: (
      <PublicRoutesValidation>
        <Outlet />
      </PublicRoutesValidation>
    ),
    children: [...PublicRoutes, ...ProtectedRoutes],
  },
]);

export default RouterModule;
