import React from "react";
import { Box, Paper } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Attachments from "../../components/Attachments/Attachments";

export default function AttachmentContainer() {
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        overflow: "auto",
      }}
    >
      <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={15}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Attachments></Attachments>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
