import React, { useState, useEffect } from "react";
import * as Fields from '../../constant/fieldTypeConstant';
import MultipleSelect from '../Common/MultipleSelect';
import DateSelector from '../Common/DateSelector';
import CheckBox from '../Common/CheckBox';

import {
    TextField,
    MenuItem
  } from "@mui/material";

  export default function FieldProvider({args, values, options, setFieldValue, onChange, onBlur, touched, errors}) {
    const [fieldId, setFieldId] = useState("");

    const inputChangeHandler = (event, fieldId, handleChange, setFieldValue) => {
      var targetVal = event.target.value;
  
      setFieldId(targetVal);
      setFieldValue(fieldId, targetVal);
     
      handleChange(event);
    };

    const renderField = () => {
        let field = null;
        switch (args.type) {
            case Fields.TextBox:
                field = (<TextField 
                            label={args.label} 
                            name={args.name} 
                            value={Object.keys(values).length > 0 ? values[args.name] : ""}
                            onChange={onChange}
                            onBlur={onBlur}
                            id={args.id} 
                            margin="dense"
                            size="small"
                            fullWidth
                            placeholder={args.placeholder} 
                            error={errors[args.name] && touched[args.name]}
                            helperText={touched[args.name] && errors[args.name]}
                            inputProps={{ }}
                          />);
                break;
            case Fields.TribeDropdown:{
              field = (<MultipleSelect 
                id={args.id}
                label={args.label} 
                name={args.name} 
                options={args.options}
                error={errors[args.name]}
                helperText={touched[args.name] && errors[args.name]}
                onBlur={onBlur}
                setFieldValue={setFieldValue} 
              >
              </MultipleSelect>)
              break;
            }
            case Fields.DatePicker:{
              field = (
                <DateSelector 
                  id={args.id}
                  name={args.name} 
                  label={args.label} 
                  value={values[args.name]} 
                  setFieldValue={setFieldValue} 
                  error={errors[args.name]}
                  helperText={touched[args.name] && errors[args.name]}
                  touched={touched[args.name]}
                  onBlur={onBlur}
                />
                )
              break;
            }
            case Fields.Dropdown: {
                field = (<TextField 
                            select 
                            margin="dense"
                            fullWidth
                            size="small"
                            value={Object.keys(values).length > 0 ? values[args.name] : fieldId}
                            name={args.name} 
                            id={args.id} 
                            label={args.label}
                            error={errors[args.name] && touched[args.name]}
                            helperText={touched[args.name] && errors[args.name]}
                            onChange={(e) => {
                              inputChangeHandler(
                                e,
                                args.name,
                                onChange,
                                setFieldValue
                              );
                            }}
                            onBlur={onBlur}
                            placeholder={args.placeholder} 
                        >
                            {options.map((btItem) => {
                          return (
                            <MenuItem key={btItem.code} value={btItem.code}>
                              {btItem.name}
                            </MenuItem>
                          );
                        })}
                        </TextField>);
                        break;
            }
            case Fields.BooleanField:{
              field = (<CheckBox  
                id={args.id}
                name={args.name} 
                label={args.label} 
                leftLabel={args.leftLabel}
                rightLabel={args.rightLabel}
                value={values[args.name]} 
                setFieldValue={setFieldValue} 
                error={errors[args.name]}
                helperText={touched[args.name] && errors[args.name]}
                onBlur={onBlur} />)
              break;
            }
            default: {
                field = (<TextField 
                            label={args.label} 
                            name={args.name} 
                            id={args.id} 
                            value={Object.keys(values).length > 0 ? values[args.name] : ""}
                            onChange={onChange}
                            onBlur={onBlur}
                            margin="dense"
                            size="small"
                            fullWidth
                            placeholder={args.placeholder} 
                            error={errors[args.name] && touched[args.name]}
                            helperText={touched[args.name] && errors[args.name]}
                            inputProps={{ }}
                          />);
            } 
        }
        return field;
    }

    return (
      <>{values ? renderField() : ""}</>
        
    );
  };
