import React, { useState, useEffect, useRef  } from "react";
import { useNavigate } from "react-router-dom";
import FieldProvider from "../Common/FieldProvider";
import fields from "../../mockData/productFields.json";
import productCategory from "../../assets/constants/productCategory.json";
import Button from "@mui/material/Button";
import * as Fields from '../../constant/fieldTypeConstant';
import DialogActions from "@mui/material/DialogActions";
import { Grid, Box, Typography } from "@mui/material";
import { Formik } from "formik";
import { createYupSchema } from "../../utility/yupSchemaCreator";
import * as Yup from "yup";
import { useUserContext } from "../../customHook/UserContext";
import { isNullorUndefined } from "../../utility/validator";
import * as ApiConfig from "../../services/apiConfiguration";
import { useAlert } from "../../customHook/useAlert";
import Table from "../Common/Table";
import LogoIndicator from "../../controls/LogoIndicator";
import ActionMenu from "../Common/MoreOptionMenu";
import dayjs from 'dayjs';
import {
  ProcessApiGetRequest,
  ProcessApiPutRequest,
  ProcessApiRequest,
  generateCrmToken,
} from "../../services/api";
import { LOGIN_PAGE } from "../../constant/routeConstant";
import {
  Record_Success_Message,
  Record_Failure_Message,
  Token_Expired_Message,
} from "../../constant/messages";

const yepSchema = fields.listOfFields.reduce(createYupSchema, {});
const validationSchema = Yup.object().shape(Object.assign({}, yepSchema));

function Products() {
    const navigate = useNavigate();
    const formikRef = useRef();
    const showAlert = useAlert();
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editRecordId, setEditRecordId] = useState("");
    const [initialDefaultValues, setInitialDefaultValues] = useState({});
    const {
      apiValue: {
        crmAccessToken,
        setCrmAccessToken,
        accountConfig,
        setAccountConfig,
      },
    } = useUserContext();
    const [productList, setProductList] = useState([]);
    const setInitialValues = () => {
      fields.listOfFields.forEach(item => {
        if(item.type === Fields.DatePicker){
          initialDefaultValues[item.id] = dayjs(new Date().$d).format('YYYY-MM-DD');
        }
        else{
          initialDefaultValues[item.id] = item.value || "";
        }
      });
    };

    const columns = [
      {
        field: "action",
        headerName: "",
        width: 30,
        sortable: false,
        renderCell: (params) => {
          const onClick = async (e) => {
            e.stopPropagation(); 
            const param = { record_Id: params.row.id };
            let selectedPDList = productList.filter(x=> x.id === param.record_Id)[0];
            if (formikRef.current) {
              Object.keys(selectedPDList).forEach((key) => {
                formikRef.current.setFieldValue(key, selectedPDList[key]);
              });
              setIsEditing(true);
              setEditRecordId(param.record_Id);
            }
          };
  
          const menuOption = [{ menuText: 'Edit', actionItem: onClick }];
          return <ActionMenu menuOption={menuOption} />
        },
      },
      {
        field: "Product_Category",
        headerName: <strong>Product Category</strong>,
        minWidth: 250
      },
      {
        field: "Product_Name",
        headerName: <strong>Product Name</strong>,
        minWidth: 300
      },
      {
        field: "Product_Code",
        headerName: <strong>Product Code</strong>,
        minWidth: 250,
        flex: 1,
      }
    ];
  
    useEffect(() => {
        setIsLoading(true);  
        RequestToken();  
        getProductList();  
        setInitialValues();  
    }, [crmAccessToken]);

    const RequestToken = async () => {
      const current_time = new Date();
      if (
        isNullorUndefined(crmAccessToken) ||
        (!isNullorUndefined(crmAccessToken) &&
          Math.round(current_time.getTime() / 1000) > crmAccessToken.expires_in)
      ) {
        var tokenValue = await generateCrmToken();
        setCrmAccessToken(tokenValue);
      }
    };

    const getProductList = async () => {
      const getTokenInfoParam = crmAccessToken?.access_token;
  
      if (!isNullorUndefined(getTokenInfoParam)) {
        setIsLoading(true);
        await ProcessApiGetRequest(
          getTokenInfoParam,
          ApiConfig.Action_Products,
          "columns=Product_Category,Product_Name,Product_Code&recordCount=200"
        )
          .then(function (apiResponse) {
            setProductList(apiResponse.data.results.data);
            setIsLoading(false);
          })
          .catch(function (exception) {setIsLoading(false);});
      }
    };

    const handleReset = () => {
      setIsEditing(false);
      setInitialValues();
    }

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setIsLoading(true);
        const productRequestObject = { };
        fields.listOfFields.forEach(item => {
          productRequestObject[item.id] = values[item.name];
        });
         
        productRequestObject["Commission_Rate"] = "0.00";
        productRequestObject["Quantity_In_Stock"] = "12792,0.00";

        if(isEditing){
          const requestJsonObject = {
            record_Id: editRecordId,
            data: {
              Section: ApiConfig.Action_Products,
              InputRequest: productRequestObject,
            },
          };

          await ProcessApiPutRequest(
            crmAccessToken.access_token,
            ApiConfig.Action_Products,
            requestJsonObject
          ).then(function (apiResponse) {
            onSaveSuccess(apiResponse, resetForm, productRequestObject);
          }).catch(function (exception) {
            onSaveFailed(exception, resetForm);
          });
          setIsEditing(false);
        }
        else{
          await ProcessApiRequest(
            crmAccessToken.access_token,
            ApiConfig.Action_Products,
            productRequestObject
          ).then(function (apiResponse) {
            onSaveSuccess(apiResponse, resetForm, productRequestObject);
          }).catch(function (exception) {
            onSaveFailed(exception, resetForm);
          });
        }
        
        setIsLoading(false);
        setSubmitting(false);
      };

      const onSaveSuccess = (apiResponse, resetForm, productRequestObject) => {
        if (
          !isNullorUndefined(apiResponse) &&
          !isNullorUndefined(apiResponse.data) & !apiResponse.data.error
        ) {
          const productId = apiResponse.data.results.id;
          productRequestObject.id = productId;
          let updateAccountConfig = { ...accountConfig };
          updateAccountConfig.ProductInfo = productRequestObject;

          setAccountConfig(updateAccountConfig);
          showAlert(Record_Success_Message, "success");

          resetForm();
          setInitialValues();
          getProductList();
        } else {
          showAlert(Record_Failure_Message, "error");
        }
      }

      const onSaveFailed = (exception, resetForm) => {
        const errorResponse = exception?.response;
            const responseCode = exception?.code;
            const responseStatus = errorResponse.status;
            if (
              responseCode === ApiConfig.ERR_BAD_REQUEST &&
              responseStatus === 400
            ) {
              
              resetForm();
              setInitialValues();
            } else if (
              exception?.code === ApiConfig.ERR_BAD_RESPONSE &&
              !isNullorUndefined(errorResponse) &&
              !isNullorUndefined(errorResponse?.data) &&
              errorResponse?.data?.error &&
              errorResponse?.data?.message === ApiConfig.INVALID_TOKEN
            ) {
              showAlert(Token_Expired_Message, "error");
              navigate(LOGIN_PAGE);
            } else showAlert(Record_Failure_Message, "error");
      }

      return(
        <Box>
          <Grid container spacing={1}>
            <Formik
              innerRef={formikRef}
              initialValues={initialDefaultValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onReset={handleReset}
            >
              {
                ({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  dirty,
                  touched,
                  values,
                  handleReset,
                  setFieldValue,
                  isSubmitting,
                }) => (
                <Box
                  sx={{ mt: 2 }}
                  ml={{
                    xs: 1,
                    sm: 5,
                  }}
                >
                  <Grid
                  container
                  item
                  xs={12}
                  sx={{ mt: 2, mb: 2, textAlign: "center" }}
                  justifyContent={"center"}
                >
                   <Typography variant="h5" style={{ color: "#1976d2" }}>
                    Products
                  </Typography>
                </Grid>
                <form onSubmit={handleSubmit}>
                <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 0, sm: 2 }}
                  >
                    {fields.listOfFields.length > 0 ? 
                    (fields.listOfFields.map((fieldItem, index)=>
                    {
                      return( 
                      <Grid key={index} item xs={12} sm={6} md={4}>
                        <FieldProvider 
                          args={fieldItem} 
                          values={values} 
                          options={fieldItem.name === "Product_Category" ? productCategory : []}
                          setFieldValue={setFieldValue} 
                          onChange={handleChange} 
                          onBlur={handleBlur} 
                          touched={touched}
                          errors={errors}
                        />
                      </Grid>);
                    })): (<div></div>)}
                  </Grid>
                  <DialogActions>
                    <Button
                      type="button"
                      className="outline"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}
                    >
                      Reset
                    </Button>
                    <Button type="submit" disabled={isSubmitting}>
                      Save
                    </Button>
                  </DialogActions>
                </form>
                {isLoading ? (
                  <LogoIndicator />
                  ) : (<div>{productList.length > 0 ? (
                    <Box
                    sx={{ mt: 2 }}
                    ml={{
                      xs: 1,
                      sm: 5,
                    }}
                  >
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{ mt: 2, mb: 2, textAlign: "center" }}
                    justifyContent={"center"}
                  >
                    <Table
                      idColumnName={"id"}
                      rows={productList}
                      columns={columns}
                      rowCount={productList.length}
                      customPageSize={5}
                      loading={isLoading}
                    />
                  </Grid>
                  </Box>) : null}</div>)
                  }
                </Box>)
              }
           
            </Formik>
          </Grid>
        </Box>
      );
}

export default Products;
