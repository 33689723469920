import { useEffect } from "react";
import { useUserContext } from "../../../customHook/UserContext";
import AppBar from "../AppBar";
import Footer from "../Footer";
import Sidebar from "../Sidebar";

const Layout = ({ children }) => {
  const {
    apiValue: { userConfig },
  } = useUserContext();

  useEffect(() => {
    switch (userConfig.type) {
      case 1:
        import("../../../assets/css/user1.css");
        break;
      case 2:
        import("../../../assets/css/user2.css");
        break;
      default: {
        import("../../../assets/css/user3.css");
      }
    }
  }, []);

  return (
    <div>
      <AppBar />
      {/* <Sidebar /> */}
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
