export const Basic_Text_Regex = /[^a-zA-Z0-9 ]/;
export const SSN_Regex = "\\d{3}-\\d{2}-?\\d{4}$";

export const ZipCode_Regex = "^\\d{5}$";

export const Decimal_Regex = "^\\d+(\\-\\d{1,2?$";
export const exclude_EscapeLabels = [
  "LongTermUnemployed",
  "NameConfirmation",
  "YouthCategoryESign",
];

export const labelFields = [
  "OptOut",
  "ThankYouMessage",
  "YouthPreamble",
  "Native AmericanSpouse",
];

export const dropdownFields = ["US State", "Location Select Dropdown"];
export const EmailFields = ["VoterEmail"];

export const Numberfields = ["VoterPostalCode"];

export const DateFields = [
  "VoterDob",
  "VoterHireDate",
  "USArmed Forces EnlistDate",
  "USArmed Forces DischargeDate",
  "ConvictionDate",
  "ReleaseDate",
  "LtuSinceDate",
];

// Retro Fields

export const RetroFields = [
  "EntryCodeEmployeeId",
  "EntryCodeQoId",
  "EntryCodeEmailAddress",
];

export const ValidateField = [
  { fields: Numberfields, type: "number" },
  { fields: EmailFields, type: "text" },
  { fields: DateFields, type: "date" },
];

// Error Handling - Variables

export const PAGE_NOT_FOUND = 404;

export const PAGE_NOT_FOUND_MESSAGE = "Shortname/SurveyOption not found.";

export const BAD_REQUEST = 400;

export const BAD_REQUEST_MESSAGE = "Bad Request Error.";

export const EncryptionKey = "-112)-1-QdES@Ultt";

export const phoneNumberAutoFormat = (phoneNumber) => {
  const number = phoneNumber.trim().replace(/[^0-9]/g, "");

  if (number.length < 4) return number;
  if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, "$1-$2");
  if (number.length < 11)
    return number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
  return number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
};

export const EinNumberAutoFormat = (einNumber) => {
  const number = einNumber.trim().replace(/[^0-9]/g, "");

  if (number.length < 3) return number;
  if (number.length < 7) return number.replace(/(\d{2})(\d{1})/, "$1-$2");
  return number.replace(/(\d{2})(\d{7})/, "$1-$2");
};

export const NoSpecialChar = (value) => {
  return value?.replace(Basic_Text_Regex, "");
}
