import LoginContainer from "../../containers/login-module/login-container";
import SignUpContainer from "../../containers/login-module/signup-container";
import ResetPasswordContainer from "../../containers/login-module/reset-password-container";
import NotFound from "../../components/Common/NotFound";

const PublicRoutes = [
  {
    children: [
      {
        path: "/",
        element: <LoginContainer />,
      },
      { path: "login", element: <LoginContainer />, index: true },
      {
        path: "forgotpassword",
        element: <SignUpContainer path="forgotpassword" />,
      },
      {
        path: "resetpassword/:code/:userEmail",
        element: <ResetPasswordContainer path="resetpassword" />,
      },
      { path: "signup", element: <SignUpContainer path="signup" /> },
      {
        path: "userregistration/:code/:userEmail",
        element: <ResetPasswordContainer path="userregistration" />,
      },
      { path: "notfound", element: <NotFound /> },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default PublicRoutes;
