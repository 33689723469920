// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  var token = sessionStorage.getItem("token") || null;
  return token;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
};

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("user", JSON.stringify(user));
};

// SET LANGUAGE WISE COMPANY DATA
export const setLanguageWiseCompanyData = (data,language) => {
  sessionStorage.setItem("languageWiseCompanyData", JSON.stringify(data));
  sessionStorage.setItem("language", language);
};

// GET LANGUAGE WISE COMPANY DATA
export const getLanguageWiseCompanyDataFromSession = () => {
 const data =  sessionStorage.getItem('languageWiseCompanyData');
 return JSON.parse(data);
};