import React, { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Grid, TextField, Paper, Box, Typography, Button } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAlert } from "../../customHook/useAlert";
import * as RouteConstant from "../../constant/routeConstant";
import { Messages, Record_Failure_Message } from "../../constant/messages";
import {
  SignUpApiRequest,
  SignUpApiV1Request,
  UpdateLoginUser,
  resetPassword,
} from "../../services/api";
import YupPassword from "yup-password";
import { isNullorUndefined } from "../../utility/validator";
import md5 from "md5";

const initialFormValues = {
  FirstName: "",
  LastName: "",
  Email: "",
  Password: "",
  ConfirmPassword: "",
};

YupPassword(Yup);
const validationSchema = (url) =>
  url.path === RouteConstant.USER_REGISTRATION
    ? Yup.object().shape({
        FirstName: Yup.string()
          .max(25, Messages.TooLong)
          .required(Messages.FirstNameRequired),
        LastName: Yup.string()
          .max(25, Messages.TooLong)
          .required(Messages.LastNameRequired),
        Email: Yup.string()
          .max(50, Messages.TooLong)
          .email(Messages.InvalidEmailMessage)
          .required(Messages.RequiredEmailMessage),
        Password: Yup.string()
          .password()
          .min(8, Messages.PasswordMinLength)
          .max(25, Messages.TooLong)
          .matches(/[0-9]/, Messages.PasswordNumberRequired)
          .matches(/[a-z]/, Messages.PasswordLowerCaseCharRequired)
          .matches(/[A-Z]/, Messages.PasswordUpperCaseCharRequired)
          .matches(/[^\w]/, Messages.PasswordSymbolRequired)
          .required(Messages.PasswordRequired),
        ConfirmPassword: Yup.string()
          .oneOf([Yup.ref("Password"), null], Messages.ConfirmPasswordMatch)
          .required(Messages.ConfirmPasswordRequired),
      })
    : Yup.object().shape({
        Password: Yup.string()
          .password()
          .min(8, Messages.PasswordMinLength)
          .max(25, Messages.TooLong)
          .matches(/[0-9]/, Messages.PasswordNumberRequired)
          .matches(/[a-z]/, Messages.PasswordLowerCaseCharRequired)
          .matches(/[A-Z]/, Messages.PasswordUpperCaseCharRequired)
          .matches(/[^\w]/, Messages.PasswordSymbolRequired)
          .required(Messages.PasswordRequired),
        ConfirmPassword: Yup.string()
          .oneOf([Yup.ref("Password"), null], Messages.ConfirmPasswordMatch)
          .required(Messages.ConfirmPasswordRequired),
      });

const ResetPassword = (url) => {
  const location = useLocation();
  const userName = location?.state?.userName;
  const userRecordId = location?.state?.recordId;
  const showAlert = useAlert();
  const history = useNavigate();
  const { code, userEmail } = useParams();
  const [submitLoader, setSubmitLoader] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    // if (!isNullorUndefined(userName)) {
    if (values.Password === values.ConfirmPassword) {
      // SignUpApiV1Request(requestObject)
      //   .then((response) => {
      //     resetForm();
      //     showAlert(response?.data?.message, "success");
      //     history(RouteConstant.LOGIN_PAGE);
      //   })
      //   .catch((error) => showAlert(error?.response?.data?.message, "error"));
      if (url.path === RouteConstant.RESET_PASSWORD) {
        // const requestObject = {
        //   email: userName,
        //   password: values.Password,
        // };
        // const requestJsonObject = {
        //   record_Id: userRecordId,
        //   data: {
        //     requestObject,
        //   },
        // };
        // UpdateLoginUser(requestJsonObject)
        //   .then(function (apiResponse) {
        //     showAlert("Password Changed Successfully.", "Success");
        //     resetForm();
        //     history(RouteConstant.LOGIN_PAGE);
        //   })
        //   .catch(function (error) {
        //     console.log(error);
        //   });
        const resetPasswordObject = {
          email: userEmail,
          password: md5(values?.Password),
          verify_token: code,
        };
        setSubmitLoader(true);
        resetPassword(resetPasswordObject)
          .then((response) => {
            if (response?.data?.status) {
              showAlert(response.data.message, "success");
              history(RouteConstant.LOGIN_PAGE);
            }
            setSubmitLoader(false);
          })
          .catch((error) => {
            showAlert(error?.response?.data?.message, "error");
            setSubmitLoader(false);
          });
      } else {
        const signUpRequestObject = {
          first_name: values.FirstName,
          last_name: values.LastName,
          email: values.Email,
          password: md5(values.Password),
          type: 1,
          verify_token: code,
        };
        setSubmitLoader(true);
        SignUpApiRequest(signUpRequestObject)
          .then((response) => {
            showAlert("User Created Successfully.", "success");
            resetForm();
            history(RouteConstant.LOGIN_PAGE);
            setSubmitLoader(false);
          })
          .catch(function (error) {
            showAlert(error?.response?.data?.message, "error");
            setSubmitLoader(false);
          });
      }
    } else {
      showAlert("Password and Confirm Password should be same.", "error");
    }
    // } else {
    //   showAlert(Record_Failure_Message, "error");
    // }
  };
  return (
    <React.Fragment>
      <div style={{ padding: 30 }}>
        <Grid>
          <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema(url)}
            onSubmit={handleSubmit}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleSubmit,
                handleChange,
                handleBlur,
              } = props;

              return (
                <Paper sx={{ height: 600 }}>
                  <form onSubmit={handleSubmit}>
                    <Box sx={{ ml: 5, mt: 2, textAlign: "center" }}>
                      <Typography variant="h5" style={{ color: "#1976d2" }}>
                        {url.path === RouteConstant.RESET_PASSWORD
                          ? "Reset Password"
                          : "Sign Up"}
                      </Typography>
                      {url.path !== RouteConstant.RESET_PASSWORD ? (
                        <div>
                          <div>
                            <TextField
                              error={errors.FirstName && touched.FirstName}
                              label="First Name"
                              name="FirstName"
                              value={values.FirstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={
                                errors.FirstName &&
                                touched.FirstName &&
                                errors.FirstName
                              }
                              fullwidth
                              margin="normal"
                              variant="outlined"
                              sx={{
                                width: 300,
                              }}
                            />
                          </div>
                          <div>
                            <TextField
                              error={errors.LastName && touched.LastName}
                              label="Last Name"
                              name="LastName"
                              value={values.LastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={
                                errors.LastName &&
                                touched.LastName &&
                                errors.LastName
                              }
                              fullwidth
                              margin="normal"
                              variant="outlined"
                              sx={{
                                width: 300,
                              }}
                            />
                          </div>
                          <div>
                            <TextField
                              error={errors.Email && touched.Email}
                              type="Email"
                              label="Your Email Address"
                              name="Email"
                              value={values.Email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={
                                errors.Email && touched.Email && errors.Email
                              }
                              fullwidth
                              margin="normal"
                              variant="outlined"
                              sx={{
                                width: 300,
                              }}
                            />
                          </div>
                        </div>
                      ) : null}

                      <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                          <TextField
                            error={errors.Email && touched.Email}
                            type="Email"
                            label="Your Email Address"
                            name="Email"
                            value={userEmail}
                            fullwidth
                            margin="normal"
                            variant="outlined"
                            sx={{
                              width: 300,
                            }}
                            inputProps={{
                              readOnly: true,
                              disableUnderline: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            error={errors.Password && touched.Password}
                            type="Password"
                            label="Password"
                            name="Password"
                            value={values.Password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.Password &&
                              touched.Password &&
                              errors.Password
                            }
                            fullwidth
                            margin="normal"
                            variant="outlined"
                            sx={{
                              width: 300,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            error={
                              errors.ConfirmPassword && touched.ConfirmPassword
                            }
                            type="Password"
                            label="Confirm Password"
                            name="ConfirmPassword"
                            value={values.ConfirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              errors.ConfirmPassword &&
                              touched.ConfirmPassword &&
                              errors.ConfirmPassword
                            }
                            fullwidth
                            margin="normal"
                            variant="outlined"
                            sx={{
                              width: 300,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={submitLoader}
                            onClick={handleSubmit}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </form>
                </Paper>
              );
            }}
          </Formik>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
