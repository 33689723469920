import axios from "axios";

export const ServiceHeathCheck = async () => {
  const _apiUrl = process.env.REACT_APP_API_LINK + "/healthcheck";

  return await axios
    .get(_apiUrl)
    .then((response) => {
      console.log(response);
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};
