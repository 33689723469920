import React, { createContext, useContext, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";

const AlertContext = createContext();

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};

export const AlertProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("info");

  const showAlert = (newMessage, newSeverity = "info") => {
    setMessage(newMessage);
    setSeverity(newSeverity);
    setOpen(true);
  };

  const hideAlert = () => {
    setOpen(false);
  };

  const styles = {
    snackbarStyleViaContentProps: {
      backgroundColor: "green",
    },
    snackbarStyleViaNestedContent: {
      backgroundColor: "lightgreen",
      color: "black",
    },
  };

  return (
    <AlertContext.Provider value={showAlert}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={8000}
        onClose={hideAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        severity={severity}
        ContentProps={{
          "aria-describedby": "message-id",
          className: styles.snackbarStyleViaContentProps,
        }}
      >
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert variant="filled" severity={severity}>
            {/* <AlertTitle>{severity}</AlertTitle> */}
            {message}
          </Alert>
        </Stack>
      </Snackbar>
    </AlertContext.Provider>
  );
};
