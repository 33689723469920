import React, { useCallback, useEffect } from "react";
import { Navigate } from "react-router-dom";
//import { getToken } from "../utility/userUtil";
import { useUserContext } from "../../customHook/UserContext";

// handle the public routes
const UserValidation = ({ children }) => {
  // const token = getToken();
  const {
    apiValue: { userConfig },
  } = useUserContext();

  const returnTo = "/company";
  const userToken = userConfig?.token;

  const check = useCallback(() => {
    if (userToken) {
      <Navigate to={returnTo} />;
    }
  }, [userToken]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
};

export default UserValidation;
