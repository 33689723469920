export function isNullorUndefined(val) {
  return val === undefined || val === null ? true : false;
}

export function isNullorUndefinedValue(val) {
  return val === undefined || val === null ? "" : val.trim();
}

export function isEmpty(val) {
  return val == null || val.trim().length === 0 ? true : false;
}

export function removeSpace(val) {
  return val.trim();
}

const StringFormatRegex = new RegExp("{-?[0-9]+}", "g");

export function FormatString(inputString, args) {
  var str = inputString;

  return str.replace(StringFormatRegex, function (item) {
    var intVal = parseInt(item.substring(1, item.length - 1));
    var replace;
    if (intVal >= 0) {
      replace = isNullorUndefinedValue(args[intVal]);
    } else if (intVal === -1) {
      replace = "{";
    } else if (intVal === -2) {
      replace = "}";
    } else {
      replace = "";
    }
    return replace;
  });
}
