import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { getLanguageWiseCompanyFeildData } from "./services/api";
import {
  getLanguageWiseCompanyDataFromSession,
  setLanguageWiseCompanyData,
} from "./utility/userUtil";
import { DEFAULT_LANGUAGE } from "./constant/languageConstant";

const defaultLangauge = DEFAULT_LANGUAGE;

const handleGetLanguageWiseData = async (language, forceFetch = false) => {
  const localData = getLanguageWiseCompanyDataFromSession(language);
  if (!forceFetch && localData) {
    return localData;
  }

  try {
    const response = await getLanguageWiseCompanyFeildData(language);
    setLanguageWiseCompanyData(response?.data?.results?.data, language);
    window.location.reload()
  } catch (error) {
    return error;
  }
};

const initializeI18n = async () => {
  const resources = {};
  const defaultTranslations = await handleGetLanguageWiseData(defaultLangauge);
  resources[defaultLangauge] = { translation: { form: defaultTranslations } };

  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      fallbackLng: defaultLangauge,
      detection: {
        checkWhitelist: true,
      },
      debug: false,
    });

  i18n.on("languageChanged", async (lng) => {
    const translations = await handleGetLanguageWiseData(lng, true);
    i18n.addResourceBundle(
      lng,
      "translation",
      { form: translations },
      true,
      true
    );
  });
};

initializeI18n();

export default i18n;
