import axios from "axios";
import {
  GET_TOKEN,
  Action_Logon,
  Action_Payment,
  Action_SignUp,
  Action_AccountRecord,
  Action_User_Record,
  LOGON_TOKEN,
  Action_User_Register,
  Action_SignUp_V1,
  Action_Forgot_Password,
  Action_Reset_Password,
  Action_Account_AttachmentsList,
  Action_Account_Attachments,
  Action_Records_Filter,
  Action_Languagewise_Company_Data,
} from "./apiConfiguration";
import { isNullorUndefined } from "../utility/validator";
import { ConfigProvider } from "../config/Provider";
import Request from ".";

export const ProcessApiRequest = async (
  accessToken,
  apiEndPoint,
  inputRequest
) => {
  const Service_Url = ConfigProvider().API_URL + "crm";

  const headers = {
    "Access-Control-Allow-Methods": "POST",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    Method: apiEndPoint,
    Bearer: accessToken,
  };

  return await axios.post(Service_Url, inputRequest, {
    headers,
  });
};

export const ProcessLeadApiRequest = async (
  accessToken,
  // apiEndPoint,
  inputRequest
) => {
  const Service_Url = ConfigProvider().API_URL + "crm/convertlead";

  const headers = {
    "Access-Control-Allow-Methods": "POST",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    // Method: apiEndPoint,
    Bearer: accessToken,
  };

  return await axios.post(Service_Url, inputRequest, {
    headers,
  });
};

export const GetRecordFieldsRequest = async (
  accessToken,
  apiEndPoint,
  qsParams
) => {
  let Service_Url = ConfigProvider().API_URL + "crm/recordById";

  const headers = {
    Method: apiEndPoint,
    Bearer: accessToken,
  };

  if (!isNullorUndefined(qsParams)) Service_Url = Service_Url + "?" + qsParams;

  return await axios.get(Service_Url, { headers });
};

export const ProcessApiPutRequest = async (
  accessToken,
  apiEndPoint,
  inputRequest
) => {
  const Service_Url = ConfigProvider().API_URL + "crm";

  const headers = {
    "Access-Control-Allow-Methods": "Put",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    Method: apiEndPoint,
    Bearer: accessToken,
  };

  return await axios.put(Service_Url, inputRequest, {
    headers,
  });
};

export const ProcessApiGetRequest = async (
  accessToken,
  apiEndPoint,
  qsParams
) => {
  let Service_Url = ConfigProvider().API_URL + "crm/records";

  if (!isNullorUndefined(qsParams)) Service_Url = Service_Url + "?" + qsParams;

  const headers = {
    "Access-Control-Allow-Methods": "GET",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    Method: apiEndPoint,
    Bearer: accessToken,
  };

  return await axios.get(Service_Url, { headers });
};

export const generateCrmToken = async () => {
  const Service_Url = ConfigProvider().API_URL + GET_TOKEN;
  const headers = {
    headers: {
      "Access-Control-Allow-Methods": "GET",
      "Access-Control-Allow-Headers": "*",
    },
  };
  const response = await axios.get(Service_Url, {
    headers,
  });
  const access_token = response.data.access_token;
  const current_time = new Date();
  current_time.setMinutes(current_time.getMinutes() + 59);
  var authConfig = {
    access_token,
    expires_in: Math.round(current_time.getTime() / 1000),
  };
  return authConfig;
};
export const LogonApiRequest = async (inputRequest) => {
  const Service_Url = ConfigProvider().API_URL + Action_Logon;
  const headers = {
    "Access-Control-Allow-Methods": "POST",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
  };
  return await axios.post(Service_Url, inputRequest, {
    headers,
  });
};
export const SignUpApiRequest = async (inputRequest) => {
  const Service_Url = ConfigProvider().API_URL + Action_SignUp;
  const headers = {
    "Access-Control-Allow-Methods": "POST",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    Bearer: LOGON_TOKEN,
  };
  return await axios.post(Service_Url, inputRequest, {
    headers,
  });
};
export const CheckUser = async (qsParams) => {
  let Service_Url = ConfigProvider().API_URL + Action_User_Record;
  if (!isNullorUndefined(qsParams)) Service_Url = Service_Url + "?" + qsParams;
  return await axios.get(Service_Url);
};
export const UpdateLoginUser = async (inputRequest) => {
  const Service_Url = ConfigProvider().API_URL + Action_User_Record;
  return await axios.post(Service_Url, inputRequest);
};
export const insertPaymentApi = async (inputRequest) => {
  const Service_Url = ConfigProvider().API_URL + Action_Payment;
  const headers = {
    "Access-Control-Allow-Methods": "POST",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
  };
  return await axios.post(Service_Url, inputRequest, { headers });
};
// export const AccountDetailsApi = async (accessToken, qsParams) => {
//   const Service_Url =
//     ConfigProvider().API_URL + Action_AccountRecord + "?" + qsParams;
//   const headers = {
//     "Access-Control-Allow-Methods": "GET",
//     "Access-Control-Allow-Headers": "*",
//     "Access-Control-Allow-Origin": "*",
//     Bearer: accessToken,
//   };
//   return await axios.get(Service_Url, { headers });
// };
export const AccountDetailsApi = async (accessToken, qsParams) => {
  try {
    const Service_Url =
      ConfigProvider().API_URL + Action_AccountRecord + "?" + qsParams;

    const res = await Request.get(Service_Url);
    return res;
  } catch (error) {
    throw error;
  }
};
export const ParallelApiCalls = async (requestObj1, requestObj2) => {
  axios
    .all([requestObj1, requestObj2])
    .then(
      axios.spread((...responses) => {
        // const responseOne = responses[0];
        // const responseTwo = responses[1];
        // use/access the results
      })
    )
    .catch((errors) => {
      console.log(errors);
    });
};
export const registerUser = async (inputRequest) => {
  const Service_Url = ConfigProvider().API_URL + Action_User_Register;
  const headers = {
    "Access-Control-Allow-Methods": "GET",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
  };
  return await axios.post(Service_Url, inputRequest, {
    headers,
  });
};
export const SignUpApiV1Request = async (inputRequest) => {
  const Service_Url = ConfigProvider().API_URL + Action_SignUp_V1;
  const headers = {
    "Access-Control-Allow-Methods": "POST",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    Bearer: LOGON_TOKEN,
  };
  return await axios.post(Service_Url, inputRequest, {
    headers,
  });
};
export const forgotPasswordEmailSend = async (inputRequest) => {
  const Service_Url = ConfigProvider().API_URL + Action_Forgot_Password;
  const headers = {
    "Access-Control-Allow-Methods": "POST",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
  };
  return await axios.post(Service_Url, inputRequest, {
    headers,
  });
};
export const resetPassword = async (inputRequest) => {
  const Service_Url = ConfigProvider().API_URL + Action_Reset_Password;
  const headers = {
    "Access-Control-Allow-Methods": "POST",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
  };
  return await axios.post(Service_Url, inputRequest, {
    headers,
  });
};

export const getRecordsByFilter = async (module, qsParams) => {
  try {
    const Service_Url = `${
      ConfigProvider().API_URL
    }${Action_Records_Filter}?module=${module}&${qsParams}`;

    const res = await Request.get(Service_Url);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAttachmentList = async (qsParams) => {
  try {
    const Service_Url =
      ConfigProvider().API_URL +
      Action_Account_AttachmentsList +
      "?" +
      qsParams;

    const res = await Request.get(Service_Url);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAttachments = async (qsParams) => {
  try {
    const Service_Url =
      ConfigProvider().API_URL + Action_Account_Attachments + "?" + qsParams;

    const res = await Request.get(Service_Url);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getLanguageWiseCompanyFeildData = async (qsParams) => {
  try {
    const Service_Url =
      ConfigProvider().API_URL + Action_Languagewise_Company_Data + "?language=" + qsParams;

    const res = await Request.get(Service_Url);
    return res;
  } catch (error) {
    throw error;
  }
};
