/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Grid, Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { Formik } from "formik";
import * as Yup from "yup";
import { isNullorUndefined } from "../../utility/validator";
import * as ApiConfig from "../../services/apiConfiguration";
import Table from "../Common/Table";
import ActionMenu from "../Common/MoreOptionMenu";

import {
  ProcessApiGetRequest,
  ProcessApiRequest,
  ProcessLeadApiRequest,
  generateCrmToken,
} from "../../services/api";
import { useUserContext } from "../../customHook/UserContext";
import { useAlert } from "../../customHook/useAlert";
import {
  Record_Success_Message,
  Record_Failure_Message,
  Token_Expired_Message,
  Lead_Record_Exist_Message,
  Lead_Success_Conversion_Mession,
  Messages,
} from "../../constant/messages";
import { LOGIN_PAGE } from "../../constant/routeConstant";
import LeadSourceList from "../../assets/constants/leadsouceTypes.json";
import { phoneNumberAutoFormat } from "../../utility/formValidations";
import LogoIndicator from "../../controls/LogoIndicator";
import { MenuItem } from "@mui/material";

const validationSchema = Yup.object().shape({
  FirstName: Yup.string()
    .required(Messages.FirstNameRequired)
    .min(3, Messages.TooShort)
    .max(30, Messages.TooLong),
  Website: Yup.string().max(50, Messages.TooLong),
  LastName: Yup.string()
    .required(Messages.LastNameRequired)
    .min(3, Messages.TooShort)
    .max(30, Messages.TooLong),
  CompanyName: Yup.string()
    .required(Messages.CompanyNameRequired)
    .max(30, Messages.TooLong),
  Email: Yup.string()
    .required(Messages.RequiredEmailMessage)
    .email(Messages.InvalidEmailMessage)
    .min(3, Messages.TooShort)
    .max(30, Messages.TooLong),
  Leadsource: Yup.string().required(Messages.LeadSourceRequired),
});

var initialDefaultValues = {
  FirstName: "",
  LastName: "",
  CompanyName: "",
  Leadsource: "",
  Website: "",
  Email: "",
  Phone: "",
  Notes: "",
};

function Leads() {
  const CHARACTER_LIMIT = 50;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const showAlert = useAlert();
  const [leadSourceId, setLeadSourceId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formValues, setFormValues] = useState(initialDefaultValues);
  const [leadInfoRows, setLeadInfoRows] = useState([]);

  const {
    apiValue: {
      crmAccessToken,
      setCrmAccessToken,
      accountConfig,
      setAccountConfig,
    },
  } = useUserContext();

  const columns = [
    {
      field: "action",
      headerName: "",
      width: 30,
      sortable: false,
      renderCell: (params) => {
        const onClick = async (e) => {
          e.stopPropagation(); 
          const param = { record_Id: params.row.id };
          await ProcessLeadApiRequest(crmAccessToken.access_token, param)
            .then(function (apiResponse) {
              getLeadInformation();
              showAlert(Lead_Success_Conversion_Mession, "success");
            })
            .catch(function (error) {
              console.log(error);
            });
        };

        const menuOption = [{menuText: 'Convert', actionItem: onClick }];
        return <ActionMenu menuOption={menuOption} />
      },
    },
    {
      field: "First_Name",
      headerName: <strong>First Name</strong>,
      minWidth: 90,
      flex: 1,
    },
    {
      field: "Last_Name",
      headerName: <strong>Last Name</strong>,
      minWidth: 90,
      flex: 1,
    },
    {
      field: "Company",
      headerName: <strong>Company</strong>,
      minWidth: 90,
      flex: 1,
    },
    {
      field: "Website",
      headerName: <strong>Website</strong>,
      minWidth: 130,
      flex: 1,
    },
    {
      field: "Phone",
      headerName: <strong>Phone</strong>,
      minWidth: 120,
      flex: 1,
    },
    {
      field: "Lead_Source",
      headerName: <strong>Lead Source</strong>,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "Email",
      headerName: <strong>Email</strong>,
      minWidth: 130,
      flex: 1,
    },
    {
      field: "Description",
      headerName: <strong>Description</strong>,
      minWidth: 150,
      flex: 1,
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    RequestToken();
    getLeadInformation();
  }, [crmAccessToken]);

  const RequestToken = async () => {
    const current_time = new Date();
    if (
      isNullorUndefined(crmAccessToken) ||
      (!isNullorUndefined(crmAccessToken) &&
        Math.round(current_time.getTime() / 1000) > crmAccessToken.expires_in)
    ) {
      var tokenValue = await generateCrmToken();
      setCrmAccessToken(tokenValue);
    }
  };

  const inputChangeHandler = (event, fieldId, handleChange, setFieldValue) => {
    //var targetKey = event.target.id;
    var targetVal = event.target.value;

    if (fieldId === "Leadsource") {
      setLeadSourceId(targetVal);
      setFieldValue(fieldId, targetVal);
    } else if (fieldId === "Phone") {
      const formattedValue = phoneNumberAutoFormat(targetVal);
      setPhoneNumber(formattedValue);
      setFieldValue(fieldId, targetVal);
    }

    handleChange(event);
  };

  const getLeadInformation = async () => {
    const getTokenInfoParam = crmAccessToken?.access_token;

    if (!isNullorUndefined(getTokenInfoParam)) {
      setIsLoading(true);
      await ProcessApiGetRequest(
        getTokenInfoParam,
        ApiConfig.Action_Leads,
        "columns=First_Name,Last_Name,Email,Lead_Source,Company,Phone,Website,Description&recordCount=10"
      )
        .then(function (apiResponse) {
          setLeadInfoRows(apiResponse.data.results.data);
          setIsLoading(false);
        })
        .catch(function (exception) {setIsLoading(false);});
    }
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setIsLoading(true);

    const leadSourceObj = LeadSourceList.find((x) => x.code === leadSourceId);

    const leadRequestObj = {
      First_Name: values.FirstName,
      Last_Name: values.LastName,
      Company: values.CompanyName,
      Lead_Source: leadSourceObj?.leadSourceName,
      Website: values.Website,
      Email: values.Email,
      Description: values.Notes,
      Phone: values.Phone?.replaceAll("-", ""),
    };

    await ProcessApiRequest(
      crmAccessToken.access_token,
      ApiConfig.Action_Leads,
      leadRequestObj
    )
      .then(function (apiResponse) {
        if (
          !isNullorUndefined(apiResponse) &&
          !isNullorUndefined(apiResponse.data) & !apiResponse.data.error
        ) {
          const leadId = apiResponse.data.results.id;
          leadRequestObj.id = leadId;
          let updateAccountConfig = { ...accountConfig };
          updateAccountConfig.LeadInfo = leadRequestObj;

          setAccountConfig(updateAccountConfig);
          showAlert(Record_Success_Message, "success");
          setLeadSourceId("");
          setPhoneNumber("");
          resetForm();
          setFormValues(initialDefaultValues);
          getLeadInformation();
        } else {
          showAlert(Record_Failure_Message, "error");
        }
      })
      .catch(function (exception) {
        const errorResponse = exception?.response;
        const responseCode = exception?.code;
        const responseStatus = errorResponse.status;
        if (
          responseCode === ApiConfig.ERR_BAD_REQUEST &&
          responseStatus === 400
        ) {
          showAlert(Lead_Record_Exist_Message, "error");
          setLeadSourceId("");
          setPhoneNumber("");
          resetForm();
          setFormValues(initialDefaultValues);
        } else if (
          exception?.code === ApiConfig.ERR_BAD_RESPONSE &&
          !isNullorUndefined(errorResponse) &&
          !isNullorUndefined(errorResponse?.data) &&
          errorResponse?.data?.error &&
          errorResponse?.data?.message === ApiConfig.INVALID_TOKEN
        ) {
          showAlert(Token_Expired_Message, "error");
          navigate(LOGIN_PAGE);
        } else showAlert(Record_Failure_Message, "error");
      });

    setIsLoading(false);
    setSubmitting(false);
  };

  return (
    <Box>
      <Grid container spacing={1}>
          <Formik
            initialValues={formValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              dirty,
              touched,
              values,
              handleReset,
              setFieldValue,
              isSubmitting,
            }) => (
              <Box
                sx={{ mt: 2 }}
                ml={{
                  xs: 1,
                  sm: 5,
                }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  sx={{ mt: 2, mb: 2, textAlign: "center" }}
                  justifyContent={"center"}
                >
                  <Typography variant="h5" style={{ color: "#1976d2" }}>
                    Lead Information
                  </Typography>
                </Grid>
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 0, sm: 2 }}
                  >
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        error={errors.FirstName && touched.FirstName}
                        label="First Name"
                        name="FirstName"
                        value={values.FirstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.FirstName &&
                          touched.FirstName &&
                          errors.FirstName
                        }
                        margin="dense"
                        size="small"
                        fullWidth
                        inputProps={{ maxlength: CHARACTER_LIMIT }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        error={errors.LastName && touched.LastName}
                        label="Last Name"
                        name="LastName"
                        value={values.LastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.LastName && touched.LastName && errors.LastName
                        }
                        margin="dense"
                        size="small"
                        fullWidth
                        inputProps={{ maxlength: CHARACTER_LIMIT }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        error={errors.CompanyName && touched.CompanyName}
                        label="Company"
                        name="CompanyName"
                        value={values.CompanyName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.CompanyName &&
                          touched.CompanyName &&
                          errors.CompanyName
                        }
                        margin="dense"
                        size="small"
                        fullWidth
                        inputProps={{ maxlength: CHARACTER_LIMIT }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        select
                        margin="dense"
                        fullWidth
                        size="small"
                        id="Leadsource"
                        name="Leadsource"
                        value={leadSourceId}
                        label="Lead Source"
                        onChange={(e) => {
                          inputChangeHandler(
                            e,
                            "Leadsource",
                            handleChange,
                            setFieldValue
                          );
                        }}
                        onBlur={handleBlur}
                        helperText={touched.Leadsource && errors.Leadsource}
                        error={errors.Leadsource && touched.Leadsource}
                      >
                        {LeadSourceList.map((btItem) => {
                          return (
                            <MenuItem key={btItem.code} value={btItem.code}>
                              {btItem.leadSourceName}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        error={errors.Website && touched.Website}
                        label="Web Site"
                        name="Website"
                        id="Website"
                        value={values.Website}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.Website && touched.Website && errors.Website
                        }
                        inputProps={{ maxlength: 50 }}
                        margin="dense"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        error={errors.Email && touched.Email}
                        label="Email"
                        name="Email"
                        type="email"
                        value={values.Email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.Email && touched.Email && errors.Email
                        }
                        margin="dense"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="Phone"
                        name="Phone"
                        id="Phone"
                        inputProps={{ maxlength: 12 }}
                        value={phoneNumber}
                        placeholder="123-456-7890"
                        onChange={(e) => {
                          inputChangeHandler(
                            e,
                            "Phone",
                            handleChange,
                            setFieldValue
                          );
                        }}
                        onBlur={handleBlur}
                        helperText={
                          errors.Phone && touched.Phone && errors.Phone
                        }
                        error={errors.Phone && touched.Phone}
                        margin="dense"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Notes"
                        name="Notes"
                        multiline
                        rows={2}
                        value={values.Notes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.Notes && touched.Notes && errors.Notes
                        }
                        margin="dense"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button
                      type="button"
                      className="outline"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}
                    >
                      Reset
                    </Button>
                    <Button type="submit" disabled={isSubmitting}>
                      Save
                    </Button>
                  </DialogActions>
                </form>
                {isLoading ? (
                  <LogoIndicator />
                  ) : (<div>{leadInfoRows.length > 0 ? (
                    <Box
                    sx={{ mt: 2 }}
                    ml={{
                      xs: 1,
                      sm: 5,
                    }}
                  >
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{ mt: 2, mb: 2, textAlign: "center" }}
                    justifyContent={"center"}
                  >
                    <Table
                      idColumnName={"id"}
                      rows={leadInfoRows}
                      columns={columns}
                      rowCount={leadInfoRows.length}
                      customPageSize={5}
                      loading={isLoading}
                    />
                  </Grid>
                  </Box>) : null}</div>)
                  }
                
              </Box>
            )}
          </Formik>
      </Grid>   
    </Box>
  );
}

export default Leads;
