import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { Grid, Box, Typography } from "@mui/material";

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({}));

function Table({
  idColumnName,
  rows,
  columns,
  rowCount,
  customPageSize,
  isLoading,
}) {
  const rowsValue = rows.map((row, index) => {
    return { ...row, id: row[idColumnName] ?? index };
  });

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: customPageSize,
    page: 0,
  });

  const getContent = () => {
    let content =
      rows.length > 0 ? (
        <div style={{ marginLeft: -40 }}>
          <div style={{ width: "100%" }}>
            <StripedDataGrid
              autoHeight
              rows={rowsValue}
              columns={columns}
              rowCount={rowCount}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[5, 10, 20, 30, 50, 100]}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              loading={isLoading}
            />
          </div>
        </div>
      ) : (
        <div>
          <Box>
            <Grid
              container
              item
              xs={12}
              sx={{ mt: 2, mb: 2, textAlign: "center" }}
              justifyContent={"center"}
            >
              <Typography variant="h6" style={{ color: "red" }}>
                Record not found!
              </Typography>
            </Grid>
          </Box>
        </div>
      );

    return content;
  };

  return getContent();
}

export default Table;
