// API Configs - Url/Endpoints;
export const Enviroment = "dev";
export const NETWORK_ERROR = "ERR_NETWORK";
export const AXIOS_ERROR = "AxiosError";
export const INVALID_TOKEN = "INVALID_TOKEN";
export const ERR_BAD_RESPONSE = "ERR_BAD_RESPONSE";
export const ERR_BAD_REQUEST = "ERR_BAD_REQUEST";

// export const API_URL = process.env.REACT_APP_API_LINK + "/api/";
export const DocuemntCorsSupportTemp = `https://cors-anywhere.herokuapp.com`;
export const IMAGE_URL = process.env.REACT_APP_API_LINK;
export const LOGON_TOKEN =
  "1000.2d0507ef22cc9011a1520705c5a6ec32.73effaa024356c2da8fcf2631b1da5c8";

// Url/Endpoints;
export const API_CONTROLLER = "elf";
export const GET_TOKEN = "crm/authToken";
export const Action_Logon = "security/loginuser";
export const Action_Accounts = "Accounts";
export const Action_Contacts = "Contacts";
export const Action_SalesOrder = "Sales_Orders";
export const Action_Invoice = "Invoice";
export const Action_Payment = "log/trasaction";
export const Action_Leads = "Leads";
export const Action_Products = "Products";
export const Action_SignUp = "security/userVerification";
export const Action_Search = "SearchRecord";
export const Action_AccountRecord = "crm/company";
export const Action_Company = "Company";
export const Action_User_Record = "account/user";
export const Action_SaveUser_Record = "user/updateRecord";
export const Action_User_Register = "security/signupUserWithEmail";
export const Action_SignUp_V1 = "users/signupv1";
export const Action_Forgot_Password = "security/forgetPassword";
export const Action_Reset_Password = "security/resetpassword";
export const Action_Account_AttachmentsList = "crm/attachmentlist";
export const Action_Account_Attachments = "crm/attachment";
export const Action_Records_Filter = "crm/recordsByFilter";
export const Action_Languagewise_Company_Data  = "crm/getcompanyfield"


// Module Section
export const Section_Payment = "Payment";
export const Section_Address = "Address";
export const Section_Company = "Company";

// Module Query String Params

export const Params_Accounts_Attachments =
  "columns=Account_Status,Account_Name&recordCount=100&searchcriteria=Account_Status:";

const httpStatusCodes = [
  {
    Status_Code: "400",
    Errors: [
      "INVALID_MODULE",
      "INVALID_DATA",
      "DUPLICATE_DATA",
      "MANDATORY_NOT_FOUND",
      "PATTERN_NOT_MATCHED",
      "INVALID_REQUEST_METHOD",
      "REQUIRED_PARAM_MISSING",
      "LICENSE_LIMIT_EXCEEDED",
    ],
  },
  {
    Status_Code: "401",
    Errors: ["AUTHENTICATION_FAILED", "OAUTH_SCOPE_MISMATCH", "INVALID_TOKEN"],
  },
  { Status_Code: "403", Errors: ["NO_PERMISSION", "FEATURE_NOT_SUPPORTED"] },
  { Status_Code: "404", Errors: ["INVALID_URL_PATTERN"] },
];
