import RouteModule from "./routes/index";
import { AlertProvider } from "./customHook/useAlert";
import { UserContextProvider } from "./customHook/UserContext";
import { RouterProvider } from "react-router-dom";
import "./styles/App.css";
import "../src/i18n";

function App() {
  return (
    <>
      <UserContextProvider>
        <AlertProvider>
          <RouterProvider router={RouteModule} />
        </AlertProvider>
      </UserContextProvider>
    </>
  );
}

export default App;
