import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SendEmail } from "../Common/SendEmail";
import { Grid, TextField, Paper, Button } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAlert } from "../../customHook/useAlert";
import { Messages } from "../../constant/messages";
import * as RouteConstant from "../../constant/routeConstant";
import {
  CheckUser,
  forgotPasswordEmailSend,
  registerUser,
} from "../../services/api";
const initialFormValues = {
  Email: "",
};

const validationSchema = Yup.object().shape({
  Email: Yup.string()
    .email(Messages.InvalidEmailMessage)
    .required(Messages.RequiredEmailMessage),
});

const SignUp = (url) => {
  const showAlert = useAlert();
  const navigate = useNavigate();
  const [submitLoader, setSubmitLoader] = useState(false);
  const handleSubmit = async (values) => {
    const qsParam = `username=${values.Email}`;

    // CheckUser(qsParam)
    //   .then(function (apiResponse) {
    //     const responseData = apiResponse.data;
    //     const recordId = responseData?.results?.data?.recordId;
    //     if (
    //       values.Email &&
    //       recordId &&
    //       url.path === RouteConstant.FORGOT_PASSWORD
    //     ) {
    //       // let resetLink = process.env.REACT_APP_LINK + RouteConstant.RESET_PASSWORD;
    //       // SendEmail(
    //       //   emailConstant.passResetEmailTempId,
    //       //   apiResponse.data.results.data.userName,
    //       //   EMAIL_FROM,
    //       //   resetLink,
    //       //   values.Email,
    //       //   showAlert
    //       // );
    //       //navigate("/" + RouteConstant.RESET_PASSWORD);

    //       navigate(
    //         "/" + RouteConstant.RESET_PASSWORD,
    //         {
    //           state: {
    //             userName: values.Email,
    //             recordId: recordId,
    //           },
    //         },
    //         { replace: true }
    //       );
    //     } else if (values.Email && url.path === "signup") {
    //       if (apiResponse.data.results.data.recordId === null) {
    //         // SendEmail(
    //         //   emailConstant.userRegEmailTemp,
    //         //   "User",
    //         //   EMAIL_FROM,
    //         //   process.env.REACT_APP_LINK + RouteConstant.USER_REGISTRATION,
    //         //   values.Email,
    //         //   showAlert
    //         // );
    //         navigate("/" + RouteConstant.USER_REGISTRATION);
    //       } else {
    //         showAlert(Messages.UserAlreadyExists, "error");
    //         navigate(RouteConstant.LOGIN_PAGE);
    //       }
    //     } else {
    //       showAlert(Messages.UserNotExists, "error");
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    if (url?.path === RouteConstant.FORGOT_PASSWORD) {
      setSubmitLoader(true);
      forgotPasswordEmailSend({ email: values.Email })
        .then((response) => {
          if (response?.data?.status) {
            showAlert(response.data.message, "success");
            navigate(RouteConstant.LOGIN_PAGE);
          }
          setSubmitLoader(false);
        })
        .catch((error) => {
          showAlert(error?.response?.data?.message, "error");
          setSubmitLoader(false);
        });
    } else {
      setSubmitLoader(true);
      registerUser({ email: values.Email })
        .then((response) => {
          if (response.data.status) {
            showAlert(response.data.message, "success");
            navigate(RouteConstant.LOGIN_PAGE);
            // navigate("/" + RouteConstant.USER_REGISTRATION);
          }
          setSubmitLoader(false);
        })
        .catch((error) => {
          showAlert(error?.response?.data?.message, "error");
          setSubmitLoader(false);
        });
    }
  };
  return (
    <React.Fragment>
      <div style={{ padding: 30 }}>
        <Grid>
          <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleSubmit,
                handleChange,
                handleBlur,
              } = props;
              return (
                <Paper sx={{ height: 250 }}>
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container
                      spacing={3}
                      direction={"column"}
                      justify={"center"}
                      alignItems={"center"}
                      sx={{}}
                    >
                      <Grid item xs={12}>
                        <TextField
                          error={errors.Email && touched.Email}
                          type="Email"
                          label="Your Email Address"
                          name="Email"
                          value={values.Email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={
                            errors.Email && touched.Email && errors.Email
                          }
                          fullwidth
                          margin="normal"
                          variant="outlined"
                          sx={{
                            width: 300,
                          }}
                        />
                      </Grid>
                      <Grid item xs={{}}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={submitLoader}
                          onClick={handleSubmit}
                        >
                          {url.path === RouteConstant.FORGOT_PASSWORD
                            ? "Reset Password"
                            : "Register"}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              );
            }}
          </Formik>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default SignUp;
