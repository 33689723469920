import * as React from "react";
import { NavLink } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
//import DashboardIcon from '@mui/icons-material/Dashboard';
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import AddressIcon from "@mui/icons-material/PostAdd";
import ReceiptIcon from "@mui/icons-material/Receipt";
//import BarChartIcon from '@mui/icons-material/BarChart';
//import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from "@mui/icons-material/Assignment";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import * as ROUTE_CONSTANT from "../../constant/routeConstant";

export const mainListItems = (
  <React.Fragment>
    <ListItemButton component={NavLink} to={ROUTE_CONSTANT.ACCOUNT_PAGE}>
      <ListItemIcon>
        <BusinessIcon />
      </ListItemIcon>
      <ListItemText primary="Account" />
    </ListItemButton>
    <ListItemButton component={NavLink} to={ROUTE_CONSTANT.NEW_ACCOUNT_PAGE}>
      <ListItemIcon>
        <BusinessIcon />
      </ListItemIcon>
      <ListItemText primary="New Account" />
    </ListItemButton>
    <ListItemButton component={NavLink} to={ROUTE_CONSTANT.LEAD_PAGE}>
      <ListItemIcon>
        <AddressIcon />
      </ListItemIcon>
      <ListItemText primary="Leads" />
    </ListItemButton>
    <ListItemButton component={NavLink} to={ROUTE_CONSTANT.ATTACHMENT_PAGE}>
      <ListItemIcon>
        <AddressIcon />
      </ListItemIcon>
      <ListItemText primary="Attachments" />
    </ListItemButton>
    <ListItemButton component={NavLink} to={ROUTE_CONSTANT.PRODUCT_PAGE}>
      <ListItemIcon>
        <BusinessIcon />
      </ListItemIcon>
      <ListItemText primary="Products" />
    </ListItemButton>
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Saved Quotes
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <RequestQuoteIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton>
  </React.Fragment>
);
