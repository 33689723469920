/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { isNullorUndefined } from "../../utility/validator";
import * as ApiConfig from "../../services/apiConfiguration";
import Table from "../Common/Table";
import {
  ProcessApiGetRequest,
  generateCrmToken,
} from "../../services/api";
import { useUserContext } from "../../customHook/UserContext";
import LogoIndicator from "../../controls/LogoIndicator";

function Accounts() {
  const [isLoading, setIsLoading] = useState(false);
  const [accountInfoRows, setAccountInfoRows] = useState([]);

  const {
    apiValue: {
      crmAccessToken,
      setCrmAccessToken
    },
  } = useUserContext();

  const columns = [
    {
      field: "First_Name",
      headerName: <strong>First Name</strong>,
      minWidth: 90,
      flex: 1,
    },
    {
      field: "Last_Name",
      headerName: <strong>Last Name</strong>,
      minWidth: 90,
      flex: 1,
    },
    {
      field: "Account_Name",
      headerName: <strong>Account Name</strong>,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "Website",
      headerName: <strong>Website</strong>,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "Phone",
      headerName: <strong>Phone</strong>,
      minWidth: 120,
      flex: 1,
    },
    {
      field: "Account_Status",
      headerName: <strong>Account Status</strong>,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "Email",
      headerName: <strong>Email</strong>,
      minWidth: 130,
      flex: 1,
    },
    {
      field: "Description",
      headerName: <strong>Description</strong>,
      minWidth: 160,
      flex: 1,
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    RequestToken();
    getAccountsInformation();
  }, [crmAccessToken]);

  const RequestToken = async () => {
    const current_time = new Date();
    if (
      isNullorUndefined(crmAccessToken) ||
      (!isNullorUndefined(crmAccessToken) &&
        Math.round(current_time.getTime() / 1000) > crmAccessToken.expires_in)
    ) {
      var tokenValue = await generateCrmToken();
      setCrmAccessToken(tokenValue);
    }
  };

  const getAccountsInformation = async () => {
    const getTokenInfoParam = crmAccessToken?.access_token;

    if (!isNullorUndefined(getTokenInfoParam)) {
      setIsLoading(true);
      await ProcessApiGetRequest(
        getTokenInfoParam,
        ApiConfig.Action_Accounts,
        "columns=First_Name,Last_Name,Email,Account_Name,Phone,Website,Description,Account_Status&recordCount=10"
      )
        .then(function (apiResponse) {
          setIsLoading(false);
          setAccountInfoRows(apiResponse.data.results.data);
        })
        .catch(function (exception) {setIsLoading(false);});
    }
  };

  return (
    <Box>
      <Grid container spacing={1}>
        {isLoading ? (
          <LogoIndicator />
        ) : (
            
                <Grid
                container
                item
                xs={12}
                sx={{ mt: 2, mb: 2, textAlign: "center" }}
                justifyContent={"center"}
                >
                  <Table
                    idColumnName={"id"}
                    rows={accountInfoRows}
                    columns={columns}
                    rowCount={accountInfoRows.length}
                    customPageSize={5}
                    loading={isLoading}
                  />
                </Grid>
              
        )}
      </Grid>
    </Box>
  );
}

export default Accounts;
