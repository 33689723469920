import { lazy } from "react";
import ProtectedLayoutValidation from "./UserLayoutValidation";
import AdminContainer from "../../containers/admin-module/dashboard-container";
import Account from "../../containers/account-container";
import NewAccount from "../../containers/admin-module/new-account-container";
import Attachment from "../../containers/admin-module/attachment-container";
import Products from "../../containers/admin-module/product-container";

const Dashboard = lazy(() => import("../../containers/dashboard-container"));
const UserInfo = lazy(() => import("../../containers/userInfo-container"));
const CompanyAddress = lazy(() =>
  import("../../containers/company-addOn-container")
);
const Agreement = lazy(() => import("../../containers/agreement-container"));
const Payment = lazy(() => import("../../containers/payment-container"));
const ReviewSign = lazy(() => import("../../containers/reviewSign-container"));
const ConfirmationPage = lazy(() =>
  import("../../components/submission/confirmation")
);
const Order = lazy(() => import("../../containers/order-container"));
const OrderCompletePage = lazy(() =>
  import("../../components/Common/OrderCompletePage")
);

const ProtectedRoutes = [
  {
    element: <ProtectedLayoutValidation role="user" />,
    children: [
      { path: "company", element: <Dashboard /> },
      { path: "user", element: <UserInfo /> },
      { path: "address", element: <CompanyAddress /> },
      { path: "agreement", element: <Agreement /> },
      { path: "payment", element: <Payment /> },
      { path: "docsign", element: <ReviewSign /> },
      { path: "complete", element: <ConfirmationPage /> },
      { path: "order", element: <Order /> },
    ],
  },
  {
    element: <ProtectedLayoutValidation role="admin" />,
    children: [
      { path: "sales/leads", element: <AdminContainer /> },
      { path: "sales/accounts", element: <Account /> },
      { path: "sales/newaccount", element: <NewAccount /> },
      { path: "sales/attachments", element: <Attachment /> },
      { path: "sales/products", element: <Products /> },
    ],
  },
  { path: "ordercompleted", element: <OrderCompletePage /> },
];

export default ProtectedRoutes;
