import { useEffect } from "react";
import { useUserContext } from "../../customHook/UserContext";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({ children }) => {
  const {
    apiValue: { userConfig },
  } = useUserContext();

  useEffect(() => {
    // const head = document.head;
    // const link = document.createElement("style");
    // link.src = `../../assets/css/${userConfig.cssFileName}`;
    // head.appendChild(link);

    switch (userConfig.type) {
      case 1:
        import("../../assets/css/user1.css");
        break;
      case 2:
        import("../../assets/css/user2.css");
        break;
      default: {
        import("../../assets/css/user1.css");
      }
    }
  }, []);

  return (
    <div>
      <Header />
      {/* <Sidebar /> */}
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
