export const FORGOT_PASSWORD = "forgotpassword";
export const USER_REGISTRATION = "userregistration";
export const RESET_PASSWORD = "resetpassword";
export const COMPANY_PAGE = "/company";
export const NEW_ACCOUNT_PAGE = "/sales/newaccount";
export const ACCOUNT_PAGE = "/sales/accounts";
export const LEAD_PAGE = "/sales/leads";
export const ATTACHMENT_PAGE = "/sales/attachments";
export const PRODUCT_PAGE = "/sales/products";
export const ADDRESS_PAGE = "/address";
export const ORDER_CONFIRM_PAGE = "/confirm";
export const LOGIN_PAGE = "/login";
export const USER_PAGE = "/user";
export const AGREEMENT_PAGE = "/agreement";
export const PAYMENT_PAGE = "/payment";
export const COMPLETE_PAGE = "/complete";
export const ORDER_COMPLETE_PAGE = "/ordercompleted";
