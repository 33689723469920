import React, { useState, useEffect } from "react";
import FieldProvider from "../Common/FieldProvider";
import fields from "../../mockData/dynamicFields.json";
import { Grid, Box, Typography } from "@mui/material";
import { Formik } from "formik";
import { createYupSchema } from "../../utility/yupSchemaCreator";
import * as Yup from "yup";

const yepSchema = fields.listOfFields.reduce(createYupSchema, {});
const validationSchema = Yup.object().shape(Object.assign({}, yepSchema));

function NewAccount() {
    const [isLoading, setIsLoading] = useState(false);
    const [formValues, setFormValues] = useState(initialDefaultValues);

    useEffect(() => {
        setIsLoading(true); 
        setFormValues(initialDefaultValues);       
      }, []);

    var initialDefaultValues = {
        firstName: "",
        lastName: "",
        title: "",
        email: "",
        weekday: "",
        dateofbirth: ""
      };

      const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setIsLoading(true);
        setIsLoading(false);
        setSubmitting(false);
      };

      return(
        <Box>
          <Grid container spacing={1}>
            <Formik
              initialValues={formValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {
                ({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  dirty,
                  touched,
                  values,
                  handleReset,
                  setFieldValue,
                  isSubmitting,
                }) => (
                <Box
                  sx={{ mt: 2 }}
                  ml={{
                    xs: 1,
                    sm: 5,
                  }}
                >
                  <Grid
                  container
                  item
                  xs={12}
                  sx={{ mt: 2, mb: 2, textAlign: "center" }}
                  justifyContent={"center"}
                >
                   <Typography variant="h5" style={{ color: "#1976d2" }}>
                    New Account
                  </Typography>
                </Grid>
                <form onSubmit={handleSubmit}>
                <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 0, sm: 2 }}
                  >
                    {fields.listOfFields.length > 0 ? 
                    (fields.listOfFields.map((fieldItem, index)=>
                    {
                      return( 
                      <Grid key={index} item xs={12} sm={6} md={4}>
                        <FieldProvider 
                          args={fieldItem} 
                          values={values} 
                          setFieldValue={setFieldValue} 
                          onChange={handleChange} 
                          onBlur={handleBlur} 
                          touched={touched}
                          errors={errors}
                        />
                      </Grid>);
                    })): (<div></div>)}
                  </Grid>
                    
                </form>
                </Box>)
              }
           
            </Formik>
          </Grid>
        </Box>
      );
}

export default NewAccount;
