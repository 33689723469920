import Axios from "axios";
import { removeUserSession } from "../utility/userUtil";

export const axios = Axios.create({
  rejectUnauthorized: false, // (NOTE: this will disable client verification)
  baseURL: process.env.REACT_APP_API_LINK,
  timeout: 1000000000,
  responseType: "json",
});

axios.interceptors.request.use(
  async (config) => {
    config.headers = {
      Accept: "application/json , */*",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " +
        JSON.parse(sessionStorage.getItem("accesstoken"))?.access_token,
      "X-Authorization": `Basic ${sessionStorage.getItem("token")}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("error :>> ", error);
    if (error?.response?.status === 403) {
      removeUserSession();
      throw new Error(error?.response?.data?.description);
    }

    return Promise.reject(error);
  }
);

export default axios;
