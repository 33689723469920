import { Navigate, Outlet } from "react-router-dom";
import Layout from "../../components/Common/Layout";
import AdminLayout from "../../components/Common/Admin/Layout";
import { useUserContext } from "../../customHook/UserContext";

const UserLayoutValidation = ({ role }) => {
  const {
    apiValue: { userConfig },
  } = useUserContext();

  const hasToken = userConfig?.token !== undefined;
  const userRole = userConfig?.type;

  return hasToken ? (
    userRole === 2 ? (
      <AdminLayout>
        <Outlet />
      </AdminLayout>
    ) : (
      <Layout>
        <Outlet />
      </Layout>
    )
  ) : (
    <Navigate to="/login" />
  );
};

export default UserLayoutValidation;
