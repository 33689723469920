const urlFallback = require("./url-fallback.json");

export const ConfigProvider = () => {
  let overrideUrl = false;

  const getApiUrl = () => {
    let _apiUrl = process.env.REACT_APP_API_LINK;
    if (!_apiUrl || overrideUrl) {
      console.error(
        "ERROR: Unable to load default domain url or ap url. Fallback urls will be used"
      );
      _apiUrl = urlFallback.API_LINK;
    }
    return _apiUrl + "/api/v1/";
  };

  const getAppUrl = () => {
    let _appUrl = process.env.REACT_APP_LINK;
    if (!_appUrl || overrideUrl) {
      _appUrl = urlFallback.APP_LINK;
    }
    return _appUrl;
  };

  const getPaymentUrl = () => {
    let _payment_public_key = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
    let _payment_secret_key = process.env.REACT_APP_STRIPE_SECRET_KEY;

    if (!_payment_public_key || !_payment_secret_key || overrideUrl) {
      _payment_public_key = urlFallback.STRIPE_PUBLIC_KEY;
      _payment_secret_key = urlFallback.STRIPE_SECRET_KEY;
    }

    return { publicKey: _payment_public_key, secretKey: _payment_secret_key };
  };

  const getApiUrlForFile = () => {
    let _apiUrl = process.env.REACT_APP_API_LINK;
    if (!_apiUrl || overrideUrl) {
      console.error(
        "ERROR: Unable to load default domain url or ap url. Fallback urls will be used"
      );
      _apiUrl = urlFallback.API_LINK;
    }
    return _apiUrl;
  };

  return {
    API_URL: getApiUrl(),
    APP_URL: getAppUrl(),
    PAYMENT_PUBLIC_KEY: getPaymentUrl().publicKey,
    PAYMENT_SECRET_KEY: getPaymentUrl().secretKey,
    API_ROUTES:getApiUrlForFile(),
  };
};
