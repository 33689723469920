import React, { createContext, useContext, useState, useEffect } from "react";

const getAccountState = () => {
  const accountInfo = sessionStorage.getItem("accountconfig");
  return accountInfo ? JSON.parse(accountInfo) : null;
};

const getCrmAccessToken = () => {
  const access_Token = sessionStorage.getItem("accesstoken");
  return access_Token ? JSON.parse(access_Token) : null;
};

const getUserState = () => {
  const userInfo = sessionStorage.getItem("userconfig");
  return userInfo ? JSON.parse(userInfo) : null;
};

const removeUserSession = () => {
  // sessionStorage.removeItem("accountconfig");
  // sessionStorage.removeItem("userconfig");
  // sessionStorage.removeItem("accesstoken");
};

const UserContext = createContext();
//context consumer hook

const useUserContext = () => {
  // get the context

  const context = useContext(UserContext);
  // if undefined, throw an error

  if (context === undefined) {
    throw new Error("useUserContext was used outside of its Provider");
  }
  return context;
};

const UserContextProvider = ({ children }) => {
  // the value that will be given to the context

  const [crmAccessToken, setCrmAccessToken] = useState(getCrmAccessToken());
  const [accountConfig, setAccountConfig] = useState(getAccountState());
  const [userConfig, setUserConfig] = useState(getUserState());
  const [logoutUser, setUserLogOut] = useState(removeUserSession());

  const contextValue = {
    apiValue: {
      crmAccessToken,
      setCrmAccessToken,
      accountConfig,
      setAccountConfig,
      userConfig,
      setUserConfig,
      logoutUser,
      setUserLogOut,
    },
  };

  useEffect(() => {
    window.sessionStorage.setItem(
      "accountconfig",
      JSON.stringify(accountConfig)
    );
    window.sessionStorage.setItem(
      "accesstoken",
      JSON.stringify(crmAccessToken)
    );
    window.sessionStorage.setItem("userconfig", JSON.stringify(userConfig));
  }, [crmAccessToken, accountConfig, userConfig]);

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export { useUserContext, UserContextProvider };
