import * as React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { object } from 'yup';

export default function DateSelector({id, name, label, setFieldValue, error, helperText, touched, onBlur}) {
  const [dateValue, setValue] = React.useState(null);
  const getError = () => {
    return error ? true: isValidDate();
  }

  const isValidDate = () => {
    if (Object.prototype.toString.call(new Date(dateValue)) === 
    "[object Date]") {
        if (isNaN(new Date(dateValue).getTime())) {
            return true;
        }
        else {
           return false;
        }
    }
    else
      return true;
}
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={dateValue}
        slotProps={{ textField: { fullWidth: true, margin:"dense" , variant: 'standard', helperText: helperText, id: id, name: name, onBlur: onBlur, error: (getError() && (typeof(touched) === object ?? touched[name])) } }}
        defaultValue={dayjs(new Date())}
        format="YYYY/MM/DD"
        onChange={(newValue) => {
          setValue(newValue);
          setFieldValue(name, dayjs(newValue?.$d).format('YYYY-MM-DD'));
        }}
      />
    </LocalizationProvider>
  );
}