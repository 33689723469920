import { Box, Grid, MenuItem, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useUserContext } from "../../customHook/UserContext";
import { isNullorUndefined } from "../../utility/validator";
import {
  getRecordsByFilter,
  generateCrmToken,
  getAttachmentList,
  getAttachments,
} from "../../services/api";
import Table from "../Common/Table";
import { saveAs } from "file-saver";
import * as ApiConfig from "../../services/apiConfiguration";
import { Record_Failure_Message } from "../../constant/messages";
import { useAlert } from "../../customHook/useAlert";
import ActionMenu from "../Common/MoreOptionMenu";
import LogoIndicator from "../../controls/LogoIndicator";
import { ConfigProvider } from "../../config/Provider";
import accountStatusProvider from "../../assets/constants/accountStatus.json";

const Attachments = () => {
  const showAlert = useAlert();
  const {
    apiValue: { crmAccessToken, setCrmAccessToken },
  } = useUserContext();

  const [isLoading, setIsLoading] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [attachmentList, setAttachmentList] = useState([]);
  const [accountStatus, setAccountStatus] = useState("");
  const [accountStatusList, setAccountStatusList] = useState([]);

  useEffect(() => {
    RequestToken();
    handleGetAccountList();
    BindDropdownList();
  }, [crmAccessToken]);

  const RequestToken = async () => {
    const current_time = new Date();
    if (
      isNullorUndefined(crmAccessToken) ||
      (!isNullorUndefined(crmAccessToken) &&
        Math.round(current_time.getTime() / 1000) > crmAccessToken.expires_in)
    ) {
      var tokenValue = await generateCrmToken();
      setCrmAccessToken(tokenValue);
    }
  };

  const BindDropdownList = async () => {
    // Display Records after User Signed the Agreement
    const accountStatusFilter = accountStatusProvider.filter(
      (a) => a.value > 3
    );
    setAccountStatusList(accountStatusFilter);
  };

  // GET ACCOOUNT LIST
  const handleGetAccountList = async () => {
    const getTokenInfoParam = crmAccessToken?.access_token;
    if (!isNullorUndefined(getTokenInfoParam)) {
      // Search Records after User Signed the Agreement, Account_Status > 3
      await getRecordsByFilter(
        ApiConfig.Action_Accounts,
        ApiConfig.Params_Accounts_Attachments + 3
      )
        .then((response) => setAccountList(response.data.results.data))
        .catch((error) => showAlert(Record_Failure_Message, "error"));
    }
  };

  // GET ATTACHMENT LIST ACCOUNT WISE
  const handleGetAccountWiseAttachmentList = async (accountId) => {
    setIsLoading(true);
    const paramsData = `module=${ApiConfig.Action_Accounts}&record_Id=${accountId}`;
    await getAttachmentList(paramsData)
      .then((response) => {
        setIsLoading(false);
        setAttachmentList(
          response?.data.data.map((item) => {
            return {
              ...item,
              accountid: accountId,
            };
          })
        );
      })
      .catch((error) => {
        setIsLoading(false);
        showAlert(Record_Failure_Message, "error");
      });
  };

  const columns = [
    {
      field: "action",
      headerName: "",
      width: 30,
      sortable: false,

      renderCell: (params) => {
        const onClick = async (e) => {
          e.stopPropagation();
          const param = `module=${ApiConfig.Action_Accounts}&record_Id=${params.row.accountid}&attachment_Id=${params.row.id}`;
          setIsLoading(true);
          await getAttachments(param)
            .then((response) => {
              setTimeout(() => {
                saveAs(
                  `${ConfigProvider().API_ROUTES + "/" + response?.data?.data}`,
                  params.row.File_Name
                );
                setIsLoading(false);
                window.open(
                  `${ConfigProvider().API_ROUTES + "/" + response?.data?.data}`
                );
              }, 1000);
            })
            .catch((error) => showAlert(Record_Failure_Message, "error"));
        };

        const menuOption = [{ menuText: "Download", actionItem: onClick }];
        return <ActionMenu menuOption={menuOption} />;
      },
    },
    {
      field: "File_Name",
      headerName: <strong>File Name</strong>,
      minWidth: 450,
      flex: 1,
    },
  ];

  return (
    <Box>
      <Grid container sx={{ display: "flex" }}>
        <Grid
          container
          xs={12}
          md={12}
          sx={{ mt: 2, mb: 2, textAlign: "center" }}
          justifyContent={"center"}
        >
          <Typography variant="h5" style={{ color: "#1976d2" }}>
            Account - Search Filter
          </Typography>
        </Grid>
        <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 2 }}>
          <Grid item xs={12} sm={6} md={4} sx={{ mt: 1 }}>
            <TextField
              select
              margin="dense"
              fullWidth
              size="small"
              id="account"
              name="Account"
              label="Account"
              onChange={(e) =>
                handleGetAccountWiseAttachmentList(e.target.value)
              }
            >
              {accountList
                ?.filter(
                  (item) =>
                    item?.Account_Status === accountStatus ||
                    accountStatus === ""
                )
                .map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.Account_Name}
                    </MenuItem>
                  );
                })}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={{ mt: 1 }}>
            <TextField
              select
              margin="dense"
              fullWidth
              size="small"
              id="account_status"
              name="account_status"
              label="Account Status"
            >
              {accountStatusList.map((option) => {
                return (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    onClick={() => setAccountStatus(option.statusName)}
                  >
                    {option.statusName}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
        </Grid>
      </Grid>

      {isLoading ? (
        <LogoIndicator />
      ) : (
        <div>
          <Box
            sx={{ mt: 2 }}
            ml={{
              xs: 1,
              sm: 5,
            }}
          >
            <Grid
              container
              item
              xs={12}
              sx={{ mt: 2, mb: 2, textAlign: "center" }}
              justifyContent={"center"}
            >
              <Table
                idColumnName={"id"}
                rows={attachmentList}
                columns={columns}
                rowCount={attachmentList.length}
                customPageSize={10}
                loading={isLoading}
              />
            </Grid>
          </Box>
        </div>
      )}
    </Box>
  );
};

export default Attachments;
