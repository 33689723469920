import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import { useNavigate } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import { Badge, IconButton, Menu, MenuItem } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useEffect, useState } from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useUserContext } from "../../customHook/UserContext";
import LogoutIcon from "@mui/icons-material/Logout";
import MoreIcon from "@mui/icons-material/MoreVert";
import SalesLogo from "../../assets/image/ssa.png";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next";
import languageList from "../../assets/constants/languageList.json";
import { DEFAULT_GRID_AUTOSIZE_OPTIONS } from "@mui/x-data-grid";
import { DEFAULT_LANGUAGE } from "../../constant/languageConstant";

export default function Header({ onOpenNav }) {
  const { i18n } = useTranslation();
  const theme = useTheme();
  const {
    apiValue: { userConfig, setUserLogOut },
  } = useUserContext();
  const menuId = "primary-search-account-menu";
  const mobileMenuId = "primary-search-account-menu-mobile";
  const history = useNavigate();
  const [anchorEl, setAnchorEl] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(false);
  const [languageAnchorEl, setLanguageAnchorEl] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isLanguageMenuOpen = Boolean(languageAnchorEl);
  const imageUrl = userConfig?.companyLogo
    ? `data:image/jpeg;base64,${userConfig.companyLogo}`
    : SalesLogo;
  const SelectedLanguage = sessionStorage.getItem("language");

  useEffect(() => {
    if (SelectedLanguage == null) {
      const language = userConfig?.preferred_language
        ? userConfig.preferred_language
        : DEFAULT_LANGUAGE;
      i18n.changeLanguage(language);
    }
  }, [i18n, userConfig]);

  const handleProfileMenuOpen = () => {
    setAnchorEl(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(false);
  };

  const handleLanguageMenuClose = () => {
    setLanguageAnchorEl(false);
  };

  const handleMenuClose = () => {
    setAnchorEl(false);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = () => {
    setMobileMoreAnchorEl(true);
  };

  const handleLanguageMenuOpen = () => {
    setLanguageAnchorEl(true);
  };

  const handleLogout = () => {
    setUserLogOut();
    sessionStorage.removeItem("languageWiseCompanyData");
    sessionStorage.removeItem("language");
    history("/login");
  };

  const renderLanguageMenu = (
    <Menu
      anchorEl={languageAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isLanguageMenuOpen}
      onClose={handleLanguageMenuClose}
    >
      {languageList.map((option) => {
        return (
          <MenuItem
            key={option.code}
            value={option.code}
            onClick={() => {
              i18n.changeLanguage(option.code);
            }}
          >
            {option.name}
          </MenuItem>
        );
      })}
    </Menu>
  );

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleLanguageMenuOpen}>
        <IconButton size="large" color="inherit">
          <LanguageIcon />
        </IconButton>
        <p>Languages</p>
      </MenuItem>

      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <AppBar
      className="headerColor"
      sx={{
        height: 64,
        zIndex: theme.zIndex.appBar + 1,
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar
          sx={{
            pr: "20px",
          }}
        >
          <img src={imageUrl} alt="logo" className="headerLogo" width={90} />
        </Toolbar>
        <Toolbar
          sx={{
            height: 1,
            px: { lg: 5 },
            pb: "5px",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <IconButton
            size="large"
            aria-label="show 4 new mails"
            color="inherit"
            aria-controls={menuId}
            onClick={handleLanguageMenuOpen}
          >
            {/* <LanguageIcon /> */}
          </IconButton>
          <IconButton
            size="large"
            aria-label="show 4 new mails"
            color="inherit"
          >
            <Badge badgeContent={4} color="error">
              <MailIcon />
            </Badge>
          </IconButton>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Badge badgeContent={17} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
            onClick={handleLogout}
          >
            <LogoutIcon fontSize="medium" />
          </IconButton>

          <IconButton
            size="large"
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
        </Toolbar>
      </div>
      {renderMobileMenu}
      {renderMenu}
      {renderLanguageMenu}
    </AppBar>
  );
}
